import { useRef } from "react";
import otplogo from "../Assets/otplogo.webp";
import bottomImg from "../Assets/bottomImg.webp";
import FamilyImg from "../Assets/familyImg.webp";
import FamilyImgMob from "../Assets/familyImgmob.webp";
import FamilyHealthImg from "../Assets/FamilyHealth.webp";
import FamilyHealthMobImg from "../Assets/familyhealthMobile.webp";
import WatchImg from "../Assets/winwatch.webp";
import WatchMobImg from "../Assets/winwatchmob.webp";
import { Spinner } from "react-bootstrap";
import "../StyleSheets/landingstyle.css";

interface LandingOTP {
  isLoading: Boolean;
  errorMsg: string;
  otp: string[];
  seconds: number;
  campaignParameter: string;
  onOTPChange: (index: number, value: string, nextRef: any) => void;
  onRemoveOTP: (index: number, previousRef: any) => void;
  onSubscribePressed: () => void;
  onResendOTPPressed: (inputRef: any) => void;
}

const LandingOTP = (props: LandingOTP) => {
  let inputRef1 = useRef(null);
  let inputRef2 = useRef(null);
  let inputRef3 = useRef(null);
  let inputRef4 = useRef(null);

  return (
    <div>
      <div className="PinSection">
        <div className="rightLogo">
          <img src={otplogo} alt="" />
        </div>
        <div className="bottomImg">
          <img src={bottomImg} alt="" />
        </div>
        <div className="PinBg"></div>
        <div className="mobileimg">
          <img
            src={
              props.campaignParameter === "family"
                ? FamilyHealthMobImg
                : props.campaignParameter === "smartwatch"
                ? WatchMobImg
                : FamilyImgMob
            }
            alt=""
          />
        </div>
        <div className="faimlybg">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="formWraper">
                  <div className="healthText">
                    <p>
                      Get <span>Unlimited</span> Access to
                    </p>
                    <p>
                      <span>Health, Fitness and Beauty Tips</span>
                    </p>
                    <p className="inputnumber">
                      PIN has been sent via sms, <br /> please enter to verify
                      PIN
                    </p>
                  </div>
                  <div className="otp_input">
                    <input
                      type="number"
                      maxLength={1}
                      ref={inputRef1}
                      value={props.otp[0]}
                      onChange={(value) =>
                        props.onOTPChange(
                          0,
                          value.target.value,
                          inputRef2.current
                        )
                      }
                      onKeyDown={(e) => {
                        {
                          if (e.keyCode === 8) {
                            props.onRemoveOTP(0, null);
                          }
                        }
                      }}
                    />
                    <input
                      type="number"
                      maxLength={1}
                      ref={inputRef2}
                      value={props.otp[1]}
                      onChange={(value) =>
                        props.onOTPChange(
                          1,
                          value.target.value,
                          inputRef3.current
                        )
                      }
                      onKeyDown={(e) => {
                        {
                          if (e.keyCode === 8) {
                            props.onRemoveOTP(1, inputRef1.current);
                          }
                        }
                      }}
                    />
                    <input
                      type="number"
                      maxLength={1}
                      ref={inputRef3}
                      value={props.otp[2]}
                      onChange={(value) =>
                        props.onOTPChange(
                          2,
                          value.target.value,
                          inputRef4.current
                        )
                      }
                      onKeyDown={(e) => {
                        {
                          if (e.keyCode === 8) {
                            props.onRemoveOTP(2, inputRef2.current);
                          }
                        }
                      }}
                    />
                    <input
                      type="number"
                      maxLength={1}
                      ref={inputRef4}
                      value={props.otp[3]}
                      onChange={(value) =>
                        props.onOTPChange(3, value.target.value, null)
                      }
                      onKeyDown={(e) => {
                        {
                          if (e.keyCode === 8) {
                            props.onRemoveOTP(3, inputRef3.current);
                          }
                        }
                      }}
                    />
                  </div>

                  <div className="sendPin">
                    {props.isLoading ? (
                      <button>
                        <Spinner></Spinner>
                      </button>
                    ) : (
                      <button onClick={props.onSubscribePressed}>
                        Subscribe
                      </button>
                    )}

                    {props.errorMsg && (
                      <div className="errowMsg">*{props.errorMsg}*</div>
                    )}
                  </div>
                  <div className="resend">
                    {props.seconds > 0 && (
                      <div className="show-count">
                        <h1>Enter 4 digit OTP received.</h1>
                        <div className="m-flex">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#fff"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <path d="M12 6L12 12 16 14"></path>
                          </svg>{" "}
                          <span> 00:{props.seconds}</span>
                        </div>
                      </div>
                    )}
                    {props.seconds <= 0 && (
                      <p>
                        Didn't receive PIN?{" "}
                        <span
                          id="resendBtn"
                          onClick={() =>
                            props.onResendOTPPressed(inputRef1.current)
                          }
                        >
                          Re-send
                        </span>
                      </p>
                    )}
                    <h3 className="note">
                      *Note: Daily Recurring Charges Rs.5+T/D
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={
              props.campaignParameter === "family"
                ? FamilyHealthImg
                : props.campaignParameter === "smartwatch"
                ? WatchImg
                : FamilyImg
            }
            alt=""
            className="absoluteImg"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingOTP;
