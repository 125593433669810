import React from "react";
import MediIQ from "../Assets/img/awareness_logo.webp";
import mainImg from "../Assets/img/MediIQ awareness.webp";
import lines from "../Assets/img/Mediq-top-lines.webp";
import "../StyleSheets/awareness.css";
const Awareness = () => {
  return (
    <div className="mediq-wraper">
      <div className="main-content">
        <div className="logo">
          <img src={MediIQ} width={190} height={190} alt="logo" />
        </div>
        <p className="mediqtext">STAY AHEAD OF YOUR</p>
        <p className="mediqhead">HEALTH WITH MEDIQ</p>
        <p className="mediqshottext">CONNECTING YOU TO PERSONALIZED CARE</p>
        <div className="mainimg">
          <img src={mainImg} width={370} height={390} alt="awareness" />
        </div>
        <div className="linesimg">
          <img src={lines} width={400} height={220} alt="lines" />
        </div>
      </div>
    </div>
  );
};

export default Awareness;
