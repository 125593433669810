import React, { useEffect, useState } from "react";
import ApiNames from "../Constants/ApiNames";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import { fetchApiPost, fetchApiGet } from "../Utils/FetchApi";
import HomeScreen from "../Screens/HomeScreen";
import LandingScreen from "../Screens/LandingScreen";
import LandingOTPScreen from "../Screens/LandingOTPScreen";
import TagManager from "react-gtm-module";
import { useSearchParams } from "react-router-dom";
import { decode } from "base-64";
import Swal from "sweetalert2";
import AwarenessScreen from "../Screens/AwarenessScreen";
import BeautyScreen from "../Screens/BeautyScreen";

export default function App() {
  const [token, setToken] = useState(null);
  const [msisdn, setMsisdn] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    generateToken();
    const tagManagerArgs = {
      gtmId: "GTM-KMTCW6P",
    };
    TagManager.initialize(tagManagerArgs);
    let _msisdn = localStorage.getItem("msisdn");
    if (_msisdn) {
      setMsisdn(_msisdn);
    } else {
      checkUser();
    }
  }, []);
  useEffect(() => {
    // localStorage.setItem("msisdn", "3365413589");
    if (token) {
      fetchCheckUser();
    }
  }, [token]);

  const generateToken = async () => {
    try {
      let data = {
        username: "QueenIsArrow",
        password: "QueenIsArrow",
      };
      let response = await fetchApiPost(ApiNames.authentication, null, data);
      if (response.status === 0) {
        setToken(response.token);
      }
    } catch (error) {
      console.log("authenticateToken error :", error);
    }
  };

  const checkUser = async () => {
    try {
      let url = window.location.href;
      if (
        window.location.pathname === "/home" &&
        url.includes("?") &&
        url.includes("user")
      ) {
        let _msisdn: string | null = searchParams.get("user");
        if (_msisdn) _msisdn = decode(_msisdn);
        if (_msisdn && _msisdn.length === 10) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "You are subscribed successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setMsisdn(_msisdn);
          localStorage.setItem("msisdn", _msisdn);
        }
      }
    } catch (error) {
      console.log("checkOperator error", error);
    }
  };

  const fetchCheckUser = async () => {
    try {
      if (msisdn) {
        let data = await fetchApiGet(token, `${ApiNames.checkUser}/${msisdn}`);
        if (data.status === 0) {
        } else if (
          data.status === -1 &&
          data.result.toLowerCase().includes("user not found")
        ) {
          localStorage.removeItem("msisdn");
          setMsisdn("");
        }
      }
    } catch (error) {
      console.log("fetchCheck user error", error);
    }
  };

  const removeMsisdn = () => {
    localStorage.removeItem("msisdn");
    setMsisdn("");
  };

  return (
    <Routes>
      <Route path="/*" element={<Navigate to={"/home"} replace={true} />} />
      <Route
        path="/home"
        element={
          <HomeScreen
            token={token}
            msisdn={msisdn}
            removeMsisdn={removeMsisdn}
          />
        }
      />
      <Route
        path="/landing"
        element={
          msisdn ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <LandingScreen token={token} />
          )
        }
      />
      <Route
        path="/phone"
        element={
          msisdn ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <LandingScreen token={token} />
          )
        }
      />
       <Route
        path="/awareness"
        element={
          msisdn ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <AwarenessScreen token={token} />
          )
        }
      />
      <Route
        path="/beauty"
        element={
          msisdn ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <BeautyScreen token={token} />
          )
        }
      />
      <Route
        path="/pin"
        element={
          msisdn ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <LandingOTPScreen token={token} />
          )
        }
      />
    </Routes>
  );
}
